<template>
  <div :class="['product-configurator', { 'editor-open': editorConfig, muchdata: editorConfig && editorConfig.items.length > 10 }]">
    <div class="configurator-summary">
      <template v-for="(group, gIndex) in groups">
        <div v-if="group.configurations.length" class="summary-group" :key="gIndex">
          <div v-if="group.title" class="group-title">{{ group.title }}</div>

          <div v-for="(config, cIndex) in group.configurations" :key="cIndex" class="group-item" role="button" @click="setEditorConfig(config.id)">
            <div class="item-name">{{ config.name }}</div>
            <component class="item-value" :is="config.component.summary" :item="config.item" />
            <div class="item-edit"><lazy-icon icon="pen" /></div>
          </div>
        </div>
      </template>
    </div>

    <transition name="editor">
      <div class="configurator-editor" v-if="editorConfig">
        <div class="editor-title">
          <a href="#" @click.prevent="setEditorConfig()"><lazy-icon icon="caret-left" font-scale="0.75" /> {{ editorConfig.name }}</a>
        </div>

        <div class="editor-content-wrapper">
          <vue-scroll :ops="scrollOptions" ref="scrollbar">
            <c-product-variant-handler class="editor-content" @isVariantChange="isVariantChange">
              <component
                :is="editorConfig.component.editor"
                :items="editorConfig.items"
                :large-preview="editorConfig.largePreview"
                :overrideVertical="!$slots[editorConfig.id]"
              />
              <div
                v-for="(otherItemsGroup, index) in editorConfig.otherItems"
                v-bind:key="`OtherItems_${editorConfig.id}_${index}`"
                class="other-items-group mt-5"
              >
                <div class="editor-title">{{ otherItemsGroup.attributeTitle }}: <span v-html="otherItemsGroup.variantName"></span></div>
                <component
                  :is="editorConfig.component.editor"
                  :items="otherItemsGroup.items"
                  :large-preview="editorConfig.largePreview"
                  :overrideVertical="!$slots[editorConfig.id]"
                />
              </div>
              <div class="content-description">
                <slot :name="editorConfig.id" />
              </div>
            </c-product-variant-handler>
          </vue-scroll>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { COMPONENT_PRODUCTCONFIGURATOR_CONFIGURATION_TYPES } from '@/constants'

import SCSS_VARIABLES from '@/assets/js/modules/scss-variables'

import VueScroll from 'vuescroll/dist/vuescroll-native'
import ProductText from '@/components/private/ProductText'
import ProductColor from '@/components/private/ProductColor'
import ProductConfiguratorEditorText from '@/components/private/ProductConfiguratorEditorText'
import ProductConfiguratorEditorColor from '@/components/private/ProductConfiguratorEditorColor'

const CONFIGURATION_TYPES = Object.values(COMPONENT_PRODUCTCONFIGURATOR_CONFIGURATION_TYPES)
const DEFAULT_CONFIGURATION_TYPE = CONFIGURATION_TYPES.find((c) => c.isDefault) ?? CONFIGURATION_TYPES[0]

export default {
  name: 'ProductConfigurator',
  components: {
    VueScroll,
    ProductText,
    ProductColor,
    ProductConfiguratorEditorText,
    ProductConfiguratorEditorColor
  },
  props: {
    configurationGroups: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      editorConfigId: '',
      // https://vuescrolljs.yvescoding.org/guide/configuration.html
      // https://github.com/YvesCoding/vuescroll/blob/dev/src/shared/global-config.js
      scrollOptions: {
        sizeStrategy: 'percent',
        detectResize: true,
        locking: true,
        maxHeight: undefined,
        scrollPanel: {
          initialScrollY: false,
          initialScrollX: false,
          scrollingY: true,
          scrollingX: false,
          speed: 300,
          easing: undefined,
          verticalNativeBarPos: 'right',
          maxHeight: undefined,
          maxWidth: undefined
        },
        rail: {
          background: SCSS_VARIABLES.colors.theme.control,
          border: 'none',
          opacity: 1,
          size: '6px',
          specifyBorderRadius: false,
          gutterOfEnds: '0px',
          gutterOfSide: '0px',
          keepShow: false
        },
        bar: {
          disable: false,
          background: SCSS_VARIABLES.colors.theme.dark,
          opacity: 1,
          size: '6px',
          showDelay: 0,
          hoverStyle: false,
          specifyBorderRadius: false,
          minSize: 0,
          onlyShowBarOnScroll: false,
          keepShow: true
        },
        scrollButton: {
          enable: false,
          background: SCSS_VARIABLES.colors.theme.dark,
          opacity: 1,
          step: 50,
          mousedownStep: 30
        },
        vuescroll: {
          wheelScrollDuration: 500,
          wheelDirectionReverse: false,
          checkShifKey: true
        }
      }
    }
  },
  computed: {
    configurationTypeMap() {
      return CONFIGURATION_TYPES.reduce((acc, type) => {
        type.types.forEach((t) => {
          acc[t] = type.component
        })
        return acc
      }, {})
    },
    configurationMap() {
      return this.groups.reduce((acc, group) => {
        group.configurations.forEach((config) => {
          acc[config.id] = config
        })
        return acc
      }, {})
    },
    groups() {
      return this.configurationGroups.map((g) => ({
        title: g.text,
        configurations: g.items.map((c) => {
          const component = this.configurationTypeMap[c.type] || DEFAULT_CONFIGURATION_TYPE.component
          const activeItem = c.items.find((i) => i.active) || null
          return {
            id: c.id,
            name: c.name,
            type: c.type,
            component,
            item: activeItem,
            items: c.items,
            otherItems: c.otherItems || [],
            largePreview: c.largePreview
          }
        })
      }))
    },
    editorConfig() {
      if (!this.editorConfigId) {
        return null
      }
      return this.configurationMap[this.editorConfigId] || null
    }
  },
  methods: {
    setEditorConfig(id = '') {
      this.editorConfigId = id
    },
    isVariantChange(variantSlug) {
      this.editorConfigId = ''
    }
  },
  watch: {
    '$root.mobilefirstBreakpoint'() {
      if (this.$refs.scrollbar) this.$refs.scrollbar.refresh()
    }
  }
}
</script>

<style lang="scss">
$productconfigurator-summary-group-grid-column-gap: 0 !default;
$productconfigurator-summary-group-grid-row-gap: $spacer !default;
$productconfigurator-summary-group-grid-columns: auto auto min-content !default;

$productconfigurator-summary-group-separator-gap: $spacer !default;
$productconfigurator-summary-group-separator-border: map-get($borders, 'base') !default;

$productconfigurator-summary-group-title-font-size: $font-size-lg !default;
$productconfigurator-summary-group-title-font-weight: $font-weight-medium !default;
$productconfigurator-summary-group-title-color: inherit !default;

$productconfigurator-summary-group-item-value-padding-x: $spacer !default;
$productconfigurator-summary-group-item-edit-color: $primary !default;

$productconfigurator-editor-bg: $white !default;
$productconfigurator-editor-transition: left 200ms ease-out !default;
$productconfigurator-editor-muchdata-min-height: 400px !default;
$productconfigurator-editor-min-height: 200px !default;

$productconfigurator-editor-title-gap: $spacer !default;
$productconfigurator-editor-title-font-size: inherit !default;
$productconfigurator-editor-title-font-weight: $font-weight-bold !default;
$productconfigurator-editor-title-color: inherit !default;

$productconfigurator-editor-content-description-gap: $spacer !default;

.product-configurator {
  position: relative;
  overflow: hidden;
  min-height: $productconfigurator-editor-min-height;
  margin-bottom: $spacer;

  .configurator-summary {
    .summary-group {
      display: grid;
      column-gap: $productconfigurator-summary-group-grid-column-gap;
      row-gap: $productconfigurator-summary-group-grid-row-gap;
      grid-template-columns: $productconfigurator-summary-group-grid-columns;
      margin-top: $productconfigurator-summary-group-separator-gap;
      padding-top: $productconfigurator-summary-group-separator-gap;
      border-top: $productconfigurator-summary-group-separator-border;

      align-items: center;

      &:first-child {
        margin-top: 0;
        padding-top: 0;
        border-top: 0 none;
      }

      .group-title {
        grid-column: 1 / 4;
        font-size: $productconfigurator-summary-group-title-font-size;
        font-weight: $productconfigurator-summary-group-title-font-weight;
        color: $productconfigurator-summary-group-title-color;
      }

      .group-item {
        display: contents;

        .item-value {
          padding: 0 $productconfigurator-summary-group-item-value-padding-x;
        }

        .item-edit {
          color: $productconfigurator-summary-group-item-edit-color;
        }
      }
    }
  }

  .configurator-editor {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: $productconfigurator-editor-bg;

    .editor-title {
      flex: 0 0 auto;
      margin-bottom: $productconfigurator-editor-title-gap;
      font-size: $productconfigurator-editor-title-font-size;
      font-weight: $productconfigurator-editor-title-font-weight;
      color: $productconfigurator-editor-title-color;

      a {
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
        text-decoration: none;
      }
    }

    .editor-content-wrapper {
      flex: 0 1 100%;
      overflow: hidden;

      .editor-content {
        .content-description {
          margin-top: $productconfigurator-editor-content-description-gap;
        }
      }

      .hasVBar {
        .editor-content {
          margin-right: 12px;
        }
      }
    }

    &.editor-enter,
    &.editor-leave-to {
      left: 100%;
    }

    &.editor-enter-active,
    &.editor-leave-active {
      overflow: hidden;
      transition: $productconfigurator-editor-transition;
    }

    &.editor-enter-to,
    &.editor-leave {
      left: 0%;
    }
  }

  &.editor-open {
    &.muchdata {
      min-height: $productconfigurator-editor-muchdata-min-height;
    }
  }
}
</style>
