<template functional>
  <div
    :id="data.id"
    :class="['product-text', data.class, data.staticClass]"
    :style="data.style"
    v-bind="data.attrs"
    v-html="props.item.name[props.locale]"
  ></div>
</template>

<script>
export default {
  name: 'NewProductText',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    locale: {
      type: String,
      default: 'de-CH'
    }
  }
}
</script>

<style lang="scss">
.product-text {
}
</style>
