<template functional>
  <div
    :id="data.id"
    :class="[
      'product-color',
      { 'is-compact': props.compact, 'is-square': props.item[props.colorUrlProperty] && props.item[props.colorUrlProperty][0] !== '#' },
      data.class,
      data.staticClass
    ]"
    :style="data.style"
    v-bind="data.attrs"
  >
    <div
      class="item-color"
      :style="{
        'background-color': props.item[props.colorProperty],
        'background-image':
          props.item[props.colorUrlProperty] != null && props.item[props.colorUrlProperty][0] !== '#'
            ? `url(${props.item[props.colorUrlProperty]})`
            : ''
      }"
    ></div>
    <div>{{ props.item[props.textProperty][props.locale] }}</div>
  </div>
</template>

<script>
export default {
  name: 'NewProductColor',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    colorProperty: {
      type: String,
      default: 'additionalDisplayData'
    },
    colorUrlProperty: {
      type: String,
      default: 'additionalDisplayData'
    },
    textProperty: {
      type: String,
      default: 'name'
    },
    compact: {
      type: Boolean,
      default: false
    },
    locale: {
      type: String,
      default: 'de-CH'
    }
  }
}
</script>

<style lang="scss">
// base
$productcolor-itemcolor-gap: $spacer * 0.5 !default;
$productcolor-itemcolor-size: 1.5em !default;
$productcolor-itemcolor-border: $border-width solid rgba($gray-700, 0.1) !default;

// compcat
$productcolor-compact-itemcolor-gap: $productcolor-itemcolor-gap !default;
$productcolor-compact-itemcolor-size: 1em !default;
$productcolor-compact-itemcolor-border: $productcolor-itemcolor-border !default;

.product-color {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .item-color {
    margin-right: $productcolor-itemcolor-gap;
    width: $productcolor-itemcolor-size;
    height: $productcolor-itemcolor-size;
    border: $productcolor-itemcolor-border;
    border-radius: 50%;

    background-size: cover;
    background-position: center;

    flex-shrink: 0;
  }

  &.is-compact {
    .item-color {
      margin-right: $productcolor-compact-itemcolor-gap;
      width: $productcolor-compact-itemcolor-size;
      height: $productcolor-compact-itemcolor-size;
      border: $productcolor-compact-itemcolor-border;
    }
  }

  &.is-square {
    .item-color {
      border-radius: 0%;
      width: calc($productcolor-itemcolor-size * 4);
      height: calc($productcolor-itemcolor-size * 3);
      flex-shrink: 0;
    }
  }
}
</style>
