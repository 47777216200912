<template>
  <div class="product-buttongroup product-configurator-editor-text">
    <b-button-group :class="['buttongroup-group']" :size="buttonSize" :vertical="$root.isMobile || vertical">
      <div class="button-container" v-for="(button, bIndex) in items" :key="`${button.href}_${bIndex}`">
        <b-button variant="outline-control" href="#" @click="$emit('product-configurator:property-change', button)" :active="button.active" class="control">
          <span v-html="button.name[$i18n.locale]"></span><lazy-icon v-if="button.active" class="check-icon" icon="b-check-circle-fill" />
        </b-button>
        <template v-if="button && button.description">
          <div v-html="button.description[$i18n.locale]" class="mb-0 alert alert-light small"></div>
        </template>
      </div>
    </b-button-group>
  </div>
</template>

<script>
import { INPUT_BUTTON_SIZE_MAP } from '@/constants';

export default {
  name: 'NewProductConfiguratorEditorText',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    vertical: {
      type: Boolean,
      default: true
    },
    largePreview: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    buttonSize() {
      return INPUT_BUTTON_SIZE_MAP[this.$root.mobilefirstBreakpoint.key]
    }
  }
}
</script>

<style lang="scss"></style>
