<template functional>
  <c-product-colors
    :id="data.id"
    :class="['product-configurator-editor-color', data.class, data.staticClass]"
    :style="data.style"
    v-bind="data.attrs"
    :colors="props.items"
    :large-preview="props.largePreview"
    extended
  />
</template>

<script>
export default {
  name: 'ProductConfiguratorEditorColor',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    largePreview: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.product-configurator-editor-color {
  margin: 3px 0 3px 3px;
}
</style>
