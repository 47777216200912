<template functional>
  <c-product-buttongroup :id="data.id" :class="['product-configurator-editor-text', data.class, data.staticClass]" :style="data.style" v-bind="data.attrs" :buttons="props.items" :vertical="props.overrideVertical ? true: props.items.length > 2" block/>
</template>

<script>
export default {
  name: 'ProductConfiguratorEditorText',
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    overrideVertical: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.product-configurator-editor-text {}
</style>
