var render = function render(_c,_vm){return _c('div',_vm._b({class:[
    'product-color',
    { 'is-compact': _vm.props.compact, 'is-square': _vm.props.item[_vm.props.colorUrlProperty] && _vm.props.item[_vm.props.colorUrlProperty][0] !== '#' },
    _vm.data.class,
    _vm.data.staticClass
  ],style:(_vm.data.style),attrs:{"id":_vm.data.id}},'div',_vm.data.attrs,false),[_c('div',{staticClass:"item-color",style:({
      'background-color': _vm.props.item[_vm.props.colorProperty],
      'background-image':
        _vm.props.item[_vm.props.colorUrlProperty] != null && _vm.props.item[_vm.props.colorUrlProperty][0] !== '#'
          ? `url(${_vm.props.item[_vm.props.colorUrlProperty]})`
          : ''
    })}),_c('div',[_vm._v(_vm._s(_vm.props.item[_vm.props.textProperty][_vm.props.locale]))])])
}
var staticRenderFns = []

export { render, staticRenderFns }