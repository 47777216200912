<template>
  <div>
    <div :class="['product-configurator', { 'editor-open': editorConfig, muchdata: editorConfig && editorConfig.items.length > 10 }]" v-if="!isLoading">
      <div class="configurator-summary">
        <template v-for="(group, gIndex) in groups">
          <div v-if="group.configurations.length" class="summary-group" :key="gIndex">
            <div v-if="group.title" class="group-title">{{ group.title }}</div>

            <div v-for="(config, cIndex) in group.configurations" :key="cIndex" class="group-item" :role="config.items.length > 1 ? 'button' : null" @click="config.items.length > 1 ? setEditorConfig(config.id) : null">
              <div class="item-name">{{ config.name }}</div>
              <template v-if="config.item !== null">
                <component class="item-value" :is="config.component.newSummary" :item="config.item" :locale="$i18n.locale" />
              </template>
              <div class="item-value text-warning" v-else>
                <lazy-icon icon="b-exclamation-circle" class="mr-1" /> {{ $t(`${tPath}.value.pleaseselect`) }}
              </div>
              <div class="item-edit"><lazy-icon icon="pen" v-if="config.items.length > 1" /></div>
            </div>
          </div>
        </template>
      </div>
      <transition name="editor">
        <div v-if="editorConfig" class="configurator-editor">
          <div class="editor-title">
            <a href="#" @click.prevent="setEditorConfig()"><lazy-icon icon="caret-left" font-scale="0.75" /> {{ editorConfig.name }}</a>
          </div>
          <div class="editor-content-wrapper">
            <vue-scroll :ops="scrollOptions" ref="scrollbar">
              <c-product-variant-handler class="editor-content" @isVariantChange="isVariantChange">
                <component :is="editorConfig.component.newEditor" :items="editorConfig.items" :large-preview="editorConfig.largePreview" @product-configurator:property-change="(selectedValue) => propertyChanged(editorConfig, selectedValue)" :vertical="editorConfig.vertical" />
                <div class="content-description">
                  <slot :name="editorConfig.id.toLowerCase()" />
                </div>
              </c-product-variant-handler>
            </vue-scroll>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="variantConfigurationIsComplete && selectedVariants.length > 1" class="variant-selector-container mt-5 mb-5">
      <p>
        <strong>{{ $t(`${tPath}.variant.multipleresults`) }}</strong>
      </p>
      <ul class="variant-selector">
        <li v-for="(variant, vIndex) in selectedVariants" :key="vIndex" @click.prevent="loadVariant(variant.urlSlug[$i18n.locale])" class="variant-selector-item position-relative">
          <a :href="variant.urlSlug[$i18n.locale]" class="stretched-link"></a>
          <img :src="variant.productImageUrl" />
          <div>
            <template v-if="variant.modell">
              <strong class="modell-title">{{ variant.modell[$i18n.locale] }}</strong><br />
            </template>
            {{ variant.titel1[$i18n.locale] }}<br />
            {{ variant.titel2[$i18n.locale] }}
            <lazy-icon v-if="variant.urlSlug[$i18n.locale] === window.location.pathname.split('/').filter(Boolean).pop()" class="check-icon" icon="b-check-circle-fill" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { COMPONENT_PRODUCTCONFIGURATOR_CONFIGURATION_TYPES } from '@/constants'

import SCSS_VARIABLES from '@/assets/js/modules/scss-variables'

import NewProductColor from '@/components/private/NewProductColor'
import NewProductConfiguratorEditorColor from '@/components/private/NewProductConfiguratorEditorColor'
import NewProductConfiguratorEditorText from '@/components/private/NewProductConfiguratorEditorText'
import NewProductText from '@/components/private/NewProductText'
import VueScroll from 'vuescroll/dist/vuescroll-native'

const CONFIGURATION_TYPES = Object.values(COMPONENT_PRODUCTCONFIGURATOR_CONFIGURATION_TYPES)
const DEFAULT_CONFIGURATION_TYPE = CONFIGURATION_TYPES.find((c) => c.isDefault) ?? CONFIGURATION_TYPES[0]

export default {
  name: 'NewProductConfigurator',
  components: {
    VueScroll,
    NewProductText,
    NewProductColor,
    NewProductConfiguratorEditorText,
    NewProductConfiguratorEditorColor
  },
  props: {
    configuration: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      editorConfigId: '',
      variantConfiguration: null,
      // https://vuescrolljs.yvescoding.org/guide/configuration.html
      // https://github.com/YvesCoding/vuescroll/blob/dev/src/shared/global-config.js
      scrollOptions: {
        sizeStrategy: 'percent',
        detectResize: true,
        locking: true,
        maxHeight: undefined,
        scrollPanel: {
          initialScrollY: false,
          initialScrollX: false,
          scrollingY: true,
          scrollingX: false,
          speed: 300,
          easing: undefined,
          verticalNativeBarPos: 'right',
          maxHeight: undefined,
          maxWidth: undefined
        },
        rail: {
          background: SCSS_VARIABLES.colors.theme.control,
          border: 'none',
          opacity: 1,
          size: '6px',
          specifyBorderRadius: false,
          gutterOfEnds: '0px',
          gutterOfSide: '0px',
          keepShow: false
        },
        bar: {
          disable: false,
          background: SCSS_VARIABLES.colors.theme.dark,
          opacity: 1,
          size: '6px',
          showDelay: 0,
          hoverStyle: false,
          specifyBorderRadius: false,
          minSize: 0,
          onlyShowBarOnScroll: false,
          keepShow: true
        },
        scrollButton: {
          enable: false,
          background: SCSS_VARIABLES.colors.theme.dark,
          opacity: 1,
          step: 50,
          mousedownStep: 30
        },
        vuescroll: {
          wheelScrollDuration: 500,
          wheelDirectionReverse: false,
          checkShifKey: true
        }
      }
    }
  },
  computed: {
    currentVariant() {
      const currentBusinessObjectId = this.configuration.businessObjectId
      const result = this.configuration.variants.find((v) => v.id === currentBusinessObjectId)
      return result
    },
    groups() {
      const result = this.configuration.groups.map((g) => {
        return {
          title: g.name[this.$i18n.locale],
          configurations: g.properties.map((p) => {
            const propertyName = this.convertToCamelCase(p.propertyName)
            const activeVariantConfigurationValue = this.variantConfiguration[propertyName]
            const activeValue = activeVariantConfigurationValue ? p.values.find((v) => v.value === activeVariantConfigurationValue) : null

            const result = {
              id: propertyName,
              name: p.name[this.$i18n.locale],
              type: p.displayType,
              vertical: p.vertical,
              component: (CONFIGURATION_TYPES.find((t) => t.types.includes(p.displayType)) ?? DEFAULT_CONFIGURATION_TYPE).component,
              item: activeValue,
              items: this.possiblePropertyValues(p)
                .map((v) => ({
                  ...v,
                  active: activeValue != null && activeValue.value === v.value
                }))
                .sort((a, b) => (!p.sortByName ? a.sortValue - b.sortValue : this.comparePropertyByName(a, b))),
              largePreview: p.largePreview
            }

            return result
          })
        }
      })

      return result
    },
    selectedVariants() {
      const result = this.configuration.variants.filter((v) => {
        return Object.keys(this.variantConfiguration).every((key) => {
          const propertyName = this.convertToCamelCase(key)
          const variantConfigurationValue = this.variantConfiguration[key]
          return variantConfigurationValue === null || v.properties[propertyName] === variantConfigurationValue
        })
      })

      return result
    },
    variantConfigurationIsComplete() {
      if (this.variantConfiguration == null) {
        return false
      }

      return this.allProperties.every((p) => {
        return this.variantConfiguration[this.convertToCamelCase(p.propertyName)]
      })
    },
    allEditorConfigs() {
      return this.groups.map((g) => g.configurations).flat()
    },
    allProperties() {
      const allGroups = this.configuration.groups
      const allGroupProperties = allGroups.map((g) => g.properties)
      const allProperties = allGroupProperties.flat()

      return allProperties
    },
    editorConfig() {
      return this.allEditorConfigs.find((c) => c.id === this.editorConfigId) ?? null
    }
  },
  methods: {
    setEditorConfig(id = '') {
      this.editorConfigId = id
    },
    possiblePropertyValues(property) {
      if (this.variantConfiguration == null) {
        return property.values
      }

      const allProperties = [...this.allProperties]
      const propertyName = this.convertToCamelCase(property.propertyName)
      const propertyIndex = allProperties.findIndex((p) => p.propertyName === property.propertyName)
      const relevantProperties = allProperties.splice(0, propertyIndex)

      const relevantVariantConfiguration = relevantProperties.reduce((configuration, p) => {
        const previousPropertyName = this.convertToCamelCase(p.propertyName)
        const variantConfigurationValue = this.variantConfiguration[previousPropertyName]

        if (variantConfigurationValue !== null) {
          configuration[previousPropertyName] = variantConfigurationValue
        }
        return configuration
      }, {})

      const validVariants = this.configuration.variants.filter((v) => {
        return Object.keys(relevantVariantConfiguration).every((key) => {
          return v.properties[key] === relevantVariantConfiguration[key]
        })
      })

      return property.values.filter((v) => {
        return validVariants.some((variant) => variant.properties[propertyName] === v.value)
      })
    },
    propertyChanged(editorConfig, selectedPropertyValue) {
      const propertyName = this.convertToCamelCase(editorConfig.id)
      this.$set(this.variantConfiguration, propertyName, selectedPropertyValue.value)

      this.allProperties.forEach((p) => {
        const propertyName = this.convertToCamelCase(p.propertyName)
        const variantConfigurationPropertyValue = this.variantConfiguration[propertyName]

        if (variantConfigurationPropertyValue == null) {
          return
        }

        const validValues = this.possiblePropertyValues(p)
        const validValue = validValues.find((v) => v.value === variantConfigurationPropertyValue)

        if (validValue) {
          return
        }

        if (validValues.length === 1) {
          this.$set(this.variantConfiguration, propertyName, validValues[0].value)
          return
        }

        this.$set(this.variantConfiguration, propertyName, null)
      })

      this.editorConfigId = ''

      if (this.selectedVariants.length === 1) {
        this.loadVariant(this.selectedVariants[0].urlSlug[this.$i18n.locale])
      }
    },
    loadVariant(variantUrlSlug) {
      this.$root.$emit('getVariant', variantUrlSlug)
    },
    isVariantChange(variantSlug) {
      this.editorConfigId = ''
    },
    propertyValueMatchesVariant(property, propertyValue, variant) {
      const propertyName = this.convertToCamelCase(property.propertyName)
      return variant.properties[propertyName] === propertyValue.value
    },
    convertToCamelCase(value) {
      return value[0].toLowerCase() + value.slice(1)
    },
    comparePropertyByName(propertyA, propertyB) {
      const nameA = propertyA.name[this.$i18n.locale]
      const nameB = propertyB.name[this.$i18n.locale]

      if (nameA < nameB) {
        return -1
      }

      if (nameA > nameB) {
        return 1
      }

      return 0
    }
  },
  mounted() {
    // TODO: Load configuration from localstore when available
    this.variantConfiguration = JSON.parse(JSON.stringify(this.currentVariant.properties))
    this.isLoading = false
  },
  watch: {
    '$root.mobilefirstBreakpoint'() {
      if (this.$refs.scrollbar) this.$refs.scrollbar.refresh()
    }
  }
}
</script>

<style lang="scss">
$productconfigurator-summary-group-grid-column-gap: 0 !default;
$productconfigurator-summary-group-grid-row-gap: $spacer !default;
$productconfigurator-summary-group-grid-columns: auto auto min-content !default;

$productconfigurator-summary-group-separator-gap: $spacer !default;
$productconfigurator-summary-group-separator-border: map-get($borders, 'base') !default;

$productconfigurator-summary-group-title-font-size: $font-size-lg !default;
$productconfigurator-summary-group-title-font-weight: $font-weight-medium !default;
$productconfigurator-summary-group-title-color: inherit !default;

$productconfigurator-summary-group-item-value-padding-x: $spacer !default;
$productconfigurator-summary-group-item-edit-color: $primary !default;

$productconfigurator-editor-bg: $white !default;
$productconfigurator-editor-transition: left 200ms ease-out !default;
$productconfigurator-editor-muchdata-min-height: 400px !default;
$productconfigurator-editor-min-height: 200px !default;

$productconfigurator-editor-title-gap: $spacer !default;
$productconfigurator-editor-title-font-size: inherit !default;
$productconfigurator-editor-title-font-weight: $font-weight-bold !default;
$productconfigurator-editor-title-color: inherit !default;

$productconfigurator-editor-content-description-gap: $spacer !default;

.variant-selector-container {
  margin-top: 0;
  padding-top: $spacer;
  border-top: $productconfigurator-summary-group-separator-border;

  .variant-selector {
    list-style: none;
    margin: 0;
    padding: 0;

    .variant-selector-item {
      margin-bottom: $spacer;
      padding-bottom: $spacer;
      border-bottom: $productconfigurator-summary-group-separator-border;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      cursor: pointer;
      position: relative;

      .modell-title {
        text-transform: uppercase;
      }

      img {
        width: 100%;
        height: 100%;
        max-width: 6em;
        flex-shrink: 0;
      }

      .check-icon {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
      }

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
}

.product-configurator {
  position: relative;
  overflow: hidden;
  min-height: $productconfigurator-editor-min-height;
  margin-bottom: $spacer;

  .configurator-summary {
    .summary-group {
      display: grid;
      column-gap: $productconfigurator-summary-group-grid-column-gap;
      row-gap: $productconfigurator-summary-group-grid-row-gap;
      grid-template-columns: $productconfigurator-summary-group-grid-columns;
      margin-top: $productconfigurator-summary-group-separator-gap;
      padding-top: $productconfigurator-summary-group-separator-gap;
      border-top: $productconfigurator-summary-group-separator-border;

      align-items: center;

      &:first-child {
        margin-top: 0;
        padding-top: 0;
        border-top: 0 none;
      }

      .group-title {
        grid-column: 1 / 4;
        font-size: $productconfigurator-summary-group-title-font-size;
        font-weight: $productconfigurator-summary-group-title-font-weight;
        color: $productconfigurator-summary-group-title-color;
      }

      .group-item {
        display: contents;

        .item-value {
          padding: 0 $productconfigurator-summary-group-item-value-padding-x;
        }

        .item-edit {
          color: $productconfigurator-summary-group-item-edit-color;
        }
      }
    }
  }

  .configurator-editor {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: $productconfigurator-editor-bg;

    .editor-title {
      flex: 0 0 auto;
      margin-bottom: $productconfigurator-editor-title-gap;
      font-size: $productconfigurator-editor-title-font-size;
      font-weight: $productconfigurator-editor-title-font-weight;
      color: $productconfigurator-editor-title-color;

      a {
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
        text-decoration: none;
      }
    }

    .editor-content-wrapper {
      flex: 0 1 100%;
      overflow: hidden;

      .editor-content {
        .content-description {
          margin-top: $productconfigurator-editor-content-description-gap;
        }
      }

      .hasVBar {
        .editor-content {
          margin-right: 12px;
        }
      }
    }

    &.editor-enter,
    &.editor-leave-to {
      left: 100%;
    }

    &.editor-enter-active,
    &.editor-leave-active {
      overflow: hidden;
      transition: $productconfigurator-editor-transition;
    }

    &.editor-enter-to,
    &.editor-leave {
      left: 0%;
    }
  }

  &.editor-open {
    &.muchdata {
      min-height: $productconfigurator-editor-muchdata-min-height;
    }
  }
}
</style>
