<template>
  <div class="product-configurator-editor-color product-colors is-extended">
    <ul class="colors-list">
      <li v-for="(color, cIndex) in colorItems" :key="`${color.value}_${cIndex}`" :class="{ active: color.active, 'large-item': largePreview }">
        <div class="list-item">
          <a :class="['color', { 'inverted-foreground-color': color.hsv && color.hsv.v < 0.5 }]" href="#" @click.prevent="$emit('product-configurator:property-change', color)" :title="color.name[$i18n.locale]" :style="{
            'background-color': color.additionalDisplayData,
            'background-image': color.additionalDisplayData !== '#' ? `url(${color.additionalDisplayData})` : ''
          }" @mouseover="$emit('color-mouseover', color.mainImage)" @mouseleave="$emit('color-mouseleave')">
            <lazy-icon v-if="color.active" class="check-icon" icon="b-check-circle-fill" />
          </a>
          <span :class="['color-name']">{{ color.name[$i18n.locale] }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import tinyColor from 'tinycolor2';

export default {
  name: 'NewProductConfiguratorEditorColor',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    largePreview: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sortedColors() {
      const uniqueColors = []
      this.items.forEach((color) => {
        uniqueColors.push(color)
      })

      if (this.extended) {
        const hueClusters = [
          { min: 0, max: 60 },
          { min: 60, max: 120 },
          { min: 120, max: 180 },
          { min: 180, max: 250 },
          { min: 250, max: 330 },
          { min: 330, max: 360 }
        ]

        return (
          []
            .concat(uniqueColors)
            .map((c) => Object.assign({ hsv: tinyColor(c.additionalDisplayData).toHsv() }, c))
            // sort by value (brightness)
            .sort((a, b) => (a.hsv.v > b.hsv.v ? -1 : a.hsv.v < b.hsv.v ? 1 : 0))
            // // // sort by cluster
            .sort(
              (a, b) =>
                hueClusters.findIndex((claster) => claster.min <= a.hsv.h && claster.max >= a.hsv.h) -
                hueClusters.findIndex((claster) => claster.min <= b.hsv.h && claster.max >= b.hsv.h)
            )
            // // // sort by cluster saturation
            .sort((a, b) =>
              hueClusters.findIndex((claster) => claster.min <= a.hsv.h && claster.max >= a.hsv.h) ===
                hueClusters.findIndex((claster) => claster.min <= b.hsv.h && claster.max >= b.hsv.h)
                ? b.hsv.v - a.hsv.v
                : 0
            )
            // // // sort by saturation and value (custom)
            .sort((a, b) =>
              (a.hsv.s <= 0.1 && a.hsv.v <= 0.9) || a.hsv.v <= 0.2 ? 1 : (b.hsv.s <= 0.1 && b.hsv.v <= 0.9) || b.hsv.v <= 0.2 ? -1 : 0
            )
        )
      }
      return uniqueColors
    },
    colorItems() {
      return this.sortedColors
    },
    activeItem() {
      return this.sortedColors.find((c) => c.active) ?? this.sortedColors[0] ?? {}
    }
  }
}
</script>

<style lang="scss">
.product-configurator-editor-color {
  margin: 3px 0 3px 3px;
}
</style>
