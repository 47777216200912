<template functional>
  <div :id="data.id" :class="['product-text', data.class, data.staticClass]" :style="data.style" v-bind="data.attrs" v-html="props.item.text">
  </div>
</template>

<script>
export default {
  name: 'ProductText',
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss">
.product-text {}
</style>
